body {
  margin: 0;
}

.ra-input-isNeedChangeBattery > div , .ra-input-isChangedBattery > div, .ra-input-hasResidualDelivery > div {
   width: 220px; 
}

.ra-input-isNeedChangeBattery > div label span, .ra-input-isChangedBattery > div label span { 
  display: inline-block; white-space: nowrap; 
}